<template>
	<v-dialog persistent max-width="764px" ref="createDialog" v-model="dialog">
		<v-form lazy-validation ref="form" v-model="valid">
			<v-card>
				<v-card-title>
					<span class="text-h5 mb-3">Create Client</span>
				</v-card-title>
				<v-card-text>
					<v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Client Name*" hint="Eg: Elitez FMCG, Nestley" :rules="rules.name" :error-messages="errors.name" v-model="object.name"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Company Registered Name*" hint="Eg: Elitez FMCG Pte Ltd" :rules="rules.registered_name" :error-messages="errors.registered_name" v-model="object.registered_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="Company Registration Number" :rules="rules.registration_number" :error-messages="errors.registration_number" v-model="object.registration_number"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="POC Name" :rules="rules.poc_name" :error-messages="errors.poc_name" v-model="object.poc_name"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="POC Designation" :rules="rules.designation" :error-messages="errors.designation" v-model="object.designation"></v-text-field>
						</v-col>
						<v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
							<v-text-field dense label="POC Contact No.*" :rules="rules.contact_number" :error-messages="errors.contact_number" v-model="object.contact_number"></v-text-field>
						</v-col>
                    </v-row>
                    <v-row>
						<v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-text-field dense clearable label="Block No." :error-messages="errors.block_number" v-model="object.block_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <v-text-field dense clearable label="Street Name" :error-messages="errors.street_name" v-model="object.street_name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-text-field dense clearable label="Unit No." :error-messages="errors.unit_number" v-model="object.unit_number"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <v-text-field dense clearable label="Building Name" :error-messages="errors.building_name" v-model="object.building_name"></v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <v-text-field dense clearable label="Postal Code" :rules="rules.postal_code" :error-messages="errors.postal_code" v-model="object.postal_code"></v-text-field>
                        </v-col>
					</v-row>
                    <v-row>
                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <small class="red--text">* Required field</small>
                        </v-col>
                    </v-row>
				</v-card-text>
				<v-card-actions class="pl-4 pr-4">
					<v-spacer></v-spacer>
					<v-btn text @click="close">Close</v-btn>
					<v-btn text color="primary" :loading="loading" @click="create">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-form>
	</v-dialog>
</template>


<script>

import { copyObject } from '@/utils/helper'

export default {
	name: 'ClientCreateDialog',
	data() {
		return {
			dialog: false,
			valid: true,
			loading: false,
			object: {
                name: '',
                registered_name: '',
                registration_number: '',
                poc_name: '',
                designation: '',
                contact_number: '',
                block_number: '',
                street_name: '',
                unit_number: '',
                building_name: '',
                postal_code: '',
			},
			rules: {
                name: [
                    (value) => !!value || 'Name is required',
                    (value) => value && value.length >= 2 && value.length <= 100 || 'Name must be between 2 and 100 characters'
                ],
                registered_name: [
                    (value) => !!value || 'Registered name is required',
                    (value) => value && value.length >= 6 && value.length <= 100 || 'Registered name must be between 6 and 100 characters'
                ],
                registration_number: [
                    (value) => {
                        if(value && value.length < 6 || value.length > 20){
                            return "Registration number must between 6 and 20 characters"
                        }
                        return true
                    }
                ],
                poc_name: [
                    (value) => {
                        if(value && value.length < 2 || value.length > 100){
                            return "POC Name must between 2 and 20 characters"
                        }
                        return true
                    }
                ],
                designation: [
                    (value) => {
                        if(value && value.length < 6 || value.length > 100){
                            return "Designation must between 6 and 20 characters"
                        }
                        return true
                    }
                ],
                contact_number: [
                    (value) => {
                        if(!/^\d+$/.test(value)) {
                            return 'Contact number must be numeric value'
                        }
                        if(value.length < 8) {
                            return 'Contact number at least 8 digits'
                        }

                        return true
                    }
                ],
                postal_code: [
                    (value) => {
                        if(!value) {
                            return true
                        }

                        if(value.length !== 6) {
                            return 'Postal code must be 6 digits'
                        }

                        if(!/^\d+$/.test(value)) {
                            return 'Postal code must be 6 digits'
                        }

                        return true
                    }
                ],
			},
			errors: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		form() {
			return this.$refs.form
		}
	},
	methods: {
		open: function() {
			this.dialog = true
		},
		close: function() {
			this.dialog = false
            this.loading = false
            this.object = { name: '', registered_name: '', registration_number: '', poc_name: '', designation: '',
                            contact_number: '', block_number: '', street_name: '', unit_number: '', building_name: '',
                            postal_code: '' }
            this.errors = {}
			this.form.resetValidation()
		},
		create: function() {
			if(this.form.validate()){
				this.loading = true
                const object = copyObject(this.object)
				this.$store.dispatch('epanel/client/createClient', object).then((response) => {
                    this.close()
                    this.$emit('created', { message: response.message })
                }).catch(() => {
                    this.loading = false
                })
			}
		}
	}
}

</script>